import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { TreeItem } from '../models/TreeItem';
import { User } from '../models/User';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})

export class ApiService {

    /**
     * reusable observables from HTTP requests
     */
    private $me?: Observable<User>;
    private $searchUser?: Observable<User>;
    private $searchFolder?: Observable<TreeItem[]>;

    constructor(
        private http: HttpClient,
        private authService: AuthService) { }

    /*
    *   GET REQUESTS
    */
    me(): Observable<User> {
        if (!this.$me)
            this.$me = this.http.get<User>(environment.serverUrl + '/api/me').pipe(
                map((user: User) => {
                    this.authService.refreshUser(user)
                    return user
                }), shareReplay(1))

        return this.$me
    }
    downloadFile(file_id: string): Observable<any> {
        let params = new HttpParams().set("file_id", file_id);

        var HTTPOptions = {
            responseType: 'blob' as 'json',
            params: params
        }

        return this.http.get<HttpResponse<Blob>>(
            environment.serverUrl + '/api/me/tree/static',
            HTTPOptions
        )
    }
    removeFile(file_id: string) {
        return this.http.post(environment.serverUrl + '/api/me/tree/delete', {
            file_id: file_id
        })
    }
    getTree(): Observable<TreeItem[]> {
        return this.http.get<TreeItem[]>(environment.serverUrl + '/api/me/tree').pipe(
            map((res: TreeItem[]) => res));
    }


    /**
     * ADMIN Api functions
     * */
    admin(): Observable<any> {
        return this.http.get(environment.serverUrl + '/api/admin').pipe(
            map((res) => {
                return res;
            }));
    }
    getLog(offset: number, limit: number, emailFilter?: string): Observable<any> {
        let params = new HttpParams().set("offset", offset.toString()).set("limit", limit.toString());
        // Se emailFilter è definito, aggiungi il parametro emailFilter alla richiesta
        if (emailFilter) {
            params = params.set('emailFilter', emailFilter);
        }

        return this.http.get(environment.serverUrl + '/api/admin/logs', { params }).pipe(
            map((res) => {
                return res;
            }));
    }
    folderDump(): Observable<any> {
        return this.http.post(environment.serverUrl + '/api/admin/configs/dropbox/dump', {}).pipe(
            map((res) => {
                return res;
            }));
    }
    exportRelations(): Observable<any> {
        return this.http.get(environment.serverUrl + '/api/admin/configs/static/relations', { responseType: 'blob' });
    }
    reloadRedis(): Observable<any> {
        return this.http.post(environment.serverUrl + '/api/admin/configs/redis/reload', {}).pipe(
            map((res) => {
                return res;
            }));
    }
    searchUser(email: string): Observable<User> {
        let params = new HttpParams().set("email", email);
        this.$searchUser = this.http.get<User>(
            environment.serverUrl + '/api/admin/users',
            { params }).pipe(
                map((res: User) => {
                    return res;
                }));
        return this.$searchUser;
    }
    getUserTree($user_id: string): Observable<TreeItem[]> {
        //let params = new HttpParams().set("email", email);
        return this.http.get<TreeItem[]>(
            environment.serverUrl + '/api/admin/users/' + $user_id + '/tree').pipe(
                map((res: TreeItem[]) => {
                    return res;
                }))

    }
    getRootTree(): Observable<TreeItem[]> {
        return this.http.get<TreeItem[]>(
            environment.serverUrl + '/api/admin/tree')
            .pipe(
                map((res: TreeItem[]) => {
                    return res;
                }))

    }
    searchFolder(folder_id: number): Observable<TreeItem[]> {
        let params = new HttpParams().set("folder_id", folder_id);
        this.$searchFolder = this.http.get<TreeItem[]>(
            environment.serverUrl + '/api/admin/folders',
            { params }).pipe(
                map((res: TreeItem[]) => {
                    return res
                })
            );
        return this.$searchFolder;
    }


    /*
    *   POST REQUESTS
    */
    login(email: string, password: string): Observable<User> {
        return this.http.post(environment.serverUrl + '/api/login', {
            email: email,
            password: password
        }).pipe(
            map((res: any) => {
                localStorage.setItem(environment.token_id, res.token);
                localStorage.setItem('login-event', 'login' + Math.random());

                this.authService.startTokenTimer()
                this.authService.refreshUser(res.user)

                return res.user;
            })
        )
    }
    changePassword(new_password: string, confirm_password: string) {
        return this.http.post(environment.serverUrl + '/api/me/changePassword', {
            'new_password': new_password,
            'confirm_password': confirm_password
        }).pipe(
            map((res: any) => {
                localStorage.setItem(environment.token_id, res.token);
                localStorage.setItem('login-event', 'login' + Math.random());

                this.authService.startTokenTimer()
                this.authService.refreshUser(res.user)

                return res.user;
            })
        )
    }

    /* Admin */
    addUsers(data: any): Observable<any> {
        return this.http.post(environment.serverUrl + '/api/admin/users', data)
    }
    addAnagrafica(data: any): Observable<any> {
        return this.http.post(environment.serverUrl + '/api/admin/users/anagrafica', data)
    }

    /* Admin */
    removeUser(id: string) {
        return this.http.delete(environment.serverUrl + '/api/admin/users/' + id)
    }
    removeIdsFromUser(user_id: string, folders: string[], files: string[]): Observable<any> {
        let data = {
            folders: folders,
            files: files
        }

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(data)
        };

        return this.http.delete(environment.serverUrl + '/api/admin/users/' + user_id + '/tree', options)
    }
    resetUser(id: string) {
        return this.http.post(environment.serverUrl + '/api/admin/users/' + id + '/reset', {})
    }

}


