import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { User } from '../../core/models/User';
import { ApiService } from '../../core/services/api.service';
import { LoadingScreenService } from '../../shared/components/loadingscreen/loadingscreen.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage {
    public errorMsg?: string;

    public showPassword = false;

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    constructor(
        private api: ApiService,
        private loadingScreenService: LoadingScreenService,
        private menuController: MenuController) {
        this.menuController.enable(false)
    }


    public login(email: string, password: string) {
        this.api.login(email, password).subscribe({
            next: (user: User) => { console.log(user); delete this.errorMsg },
            error: (err) => {
                delete this.errorMsg
                console.log(err)

                if (err.status == 400) {
                    this.errorMsg = "Email o password non validi."
                }
                if (err.status == 404) {
                    this.errorMsg = "Utente non trovato o credenziali errate."
                }
            },
            complete: () => this.loadingScreenService.setLoading(false)
        })
    }
}
