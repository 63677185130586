<ion-content>
    <ion-tabs>
        <!-- Tab bar -->
        <ion-tab-bar slot="bottom">
            <ion-tab-button tab="gestioneAccessi">
                <ion-icon name="key-outline"></ion-icon>
            </ion-tab-button>
            <!--<ion-tab-button tab="gestioneUtenti">
                <ion-icon name="people-outline"></ion-icon>
            </ion-tab-button>-->
            <ion-tab-button tab="logs">
                <ion-icon name="document-outline"></ion-icon>
            </ion-tab-button>
            <ion-tab-button *ngIf="superAdmin" tab="configs">
                <ion-icon name="cog-outline"></ion-icon>
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
</ion-content>