<ion-app>
    <ion-split-pane contentId="my-content">

        <ion-menu id="sidenav" disabled="true" menuId="sidenav" contentId="my-content" type="overlay">
            <div class="background-image"></div>
            <div class="blur-overlay"></div> <!-- Overlay con effetto blur -->

            <ion-content>
                <ion-list class="p-0">
                    <ion-item color="transparent" lines="none">
                        <ion-avatar slot="start">
                            <ion-img src="/assets/images/favicon-noborder.png"></ion-img></ion-avatar>
                        <ion-label class="ion-text-wrap">Piattaforma Documentale</ion-label>
                    </ion-item>
                    <br>
                    <ion-item lines="none" color="light" *ngIf="user?.user_id">
                        <ion-avatar slot="start">
                            <ion-img src="/assets/images/avatar.svg"></ion-img>
                        </ion-avatar>
                        <ion-col>
                            <ion-label><b>{{user?.nome}} {{user?.cognome}}</b></ion-label>
                            <ion-label>{{user?.email}}</ion-label>
                            <ion-label><small>{{user?.ragione_sociale}}</small></ion-label>
                        </ion-col>
                    </ion-item>

                    <br>
                    <ion-item color="transparent" button lines="none" href="https://www.faedis.org/" rel="noopener"
                        target="_blank">
                        <ion-icon size="small" slot="start" name="home-outline"></ion-icon>
                        <ion-label>Home</ion-label>
                    </ion-item>

                    <ion-item color="transparent" lines="none" button href="mailto:assistenza@faedis.org"
                        rel="noopener">
                        <ion-icon size="small" slot="start" name="chatbubble-ellipses-outline"></ion-icon>
                        <ion-label>Contatta l'assistenza</ion-label>
                    </ion-item>


                    <ion-item color="transparent" button lines="none" href="https://www.faedis.org/?page_id=359"
                        rel="noopener" target="_blank">
                        <ion-icon size="small" slot="start" name="document-text-outline"></ion-icon>
                        <ion-label>Privacy policy</ion-label>
                    </ion-item>

                    <!--<ion-item buttons lines="none" slot="end">
                        <ion-icon size="small" slot="start" name="moon-outline"></ion-icon>
                        <ion-label>Tema scuro</ion-label>
                        <ion-toggle (ionChange)="onToggleColorTheme($event)" slot="end"></ion-toggle>
                    </ion-item>-->


                    <ion-item color="transparent" button lines="none" (click)="logout()" *ngIf="user">
                        <ion-icon size="small" slot="start" name="log-out-outline"></ion-icon>
                        <ion-label>Esci</ion-label>
                    </ion-item>
                </ion-list>

            </ion-content>
        </ion-menu>

        <ion-router-outlet id="my-content"></ion-router-outlet>

    </ion-split-pane>

    <app-loadingscreen></app-loadingscreen>

</ion-app>