import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { environment } from '../environments/environment';
import { User } from './core/models/User';
import { AuthService } from './core/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
    public user?: User | null;

    constructor(private auth: AuthService, private router: Router,
        private menuController: MenuController) { }

    ngOnInit() {

        if (environment.maintenance) {
            this.router.navigate(['/maintenance'])
        } else {
            this.auth.user$.subscribe({
                next: (user: User | null) => {
                    this.user = user

                    if (user?.user_id) {
                        if (user.isFirstPasswordChanged) {
                            if (user.isAdmin)
                                this.router.navigate(['/admin'])
                            else
                                this.router.navigate(['/folders'])
                        } else {
                            this.router.navigate(['/change-password'])
                        }
                    } else {
                        this.router.navigate(['/login'])
                    }
                },
                error: (err) => { console.log(err) }
            })
        }
    }

    logout() {
        this.menuController.enable(false);
        this.auth.logoutUser()
        location.reload();
    }
}
