import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { User } from '../../core/models/User';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { ToastService } from '../../core/services/toast.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.page.html',
    styleUrls: ['./change-password.page.scss'],
})
export class ChangePassword implements OnInit {

    form: FormGroup;
    private user?: User;
    public showPassword = false;

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    constructor(
        private auth: AuthService,
        private api: ApiService,
        private toastService: ToastService,
        private router: Router,
        private menuController: MenuController) {
        this.menuController.enable(false);
        this.form = new FormGroup({
            newPwd: new FormControl('', Validators.minLength(12)),
            confPwd: new FormControl('', Validators.minLength(12))
        }, this.passwordMatch)
    }

    ngOnInit() {
        this.auth.user$.subscribe((user: User | null) => this.user = user!)
    }

    private passwordMatch: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        let pass = group.get('newPwd')?.value;
        let confirmPass = group.get('confPwd')?.value
        return pass === confirmPass ? null : { notSame: true }
    }


    onSubmit() {
        this.api.changePassword(this.form.value.newPwd, this.form.value.confPwd).subscribe({
            next: () => {
                this.toastService.showSuccess("Password modificata", "La sua password è stata modificata con successo.", 3000)
                this.router.navigate(['/folders']);
            },
            error: (err) => {
                this.toastService.showAlert("Errore", "È avvenuto un errore, si prega di ricaricare la pagina.", 3000)
                console.error(err)
            }
        });
    }

}
