import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../models/User';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})

export class AuthService implements OnDestroy {

    private _user = new BehaviorSubject<User | null>({});
    public user$: Observable<User | null> = this._user.asObservable();

    public isLoggedIn: boolean = false;

    constructor(private toastService: ToastService) {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    ngOnDestroy(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
    }

    loginUser(token: string, user: User): void {
        localStorage.setItem(environment.token_id, token);
        localStorage.setItem('login-event', 'login' + Math.random());
        this.startTokenTimer()
        this.isLoggedIn = true;
        this._user.next(user)
    }

    logoutUser(): void {
        localStorage.setItem('logout-event', 'logout' + Math.random());
        localStorage.removeItem(environment.token_id);
        this.isLoggedIn = false;
        this._user.next(null)
    }

    refreshUser(user: User): void {
        this.isLoggedIn = true;
        this._user.next(user);
    }

    private getTokenRemainingTime() {
        const accessToken = localStorage.getItem(environment.token_id);
        if (!accessToken) {
            return 0;
        }
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
    }

    startTokenTimer() {
        const timeout = this.getTokenRemainingTime();

        of(true).pipe(
            delay(timeout),
            tap(() => {
                this.toastService.showWarning("Sessione scaduta", "Si prega di effettuare nuovamente il login", 3000)
                this.logoutUser();
            })
        ).subscribe();
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea === localStorage) {
            if (event.key === 'logout-event') {
                this._user.next({})
                location.reload();
            }
            if (event.key === 'login-event') {
                location.reload();
            }
        }
    }



}


