<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar color="light">
        <ion-buttons slot="start">
            <ion-menu-button menuId="sidenav"></ion-menu-button>
        </ion-buttons>
        <ion-title>Le tue cartelle</ion-title>
    </ion-toolbar>
</ion-header>


<ion-content>

    <ion-grid>
        <app-foldertree [folders]="folders" [options]="CLIENT_TREE_OPTIONS"
            (selectedNode)="onSelectedNodeChanges($event)">
        </app-foldertree>

        <!--
    <context-menu>

        <ng-template contextMenuItem>

            <ion-item button lines="full">
                <ion-icon size="small" slot="start" color="primary" name="cloud-upload-outline"></ion-icon>
                <input onclick="this.value=null" id="input_files" type="file" ngFileSelect [options]="options"
                    (uploadOutput)="onUploadOutput($event, selectedNode)" [uploadInput]="uploadInput" multiple />
            </ion-item>


            <ion-item button lines="full" [disabled]="selectedNode.type == 'dir'" type="button"
                (click)="createLink(selectedNode)">
                <ion-icon size="small" slot="start" color="primary" name="link-outline"></ion-icon>
                Crea link di condivisione
            </ion-item>



            <ion-item button lines="full" [disabled]="selectedNode.type == 'dir'" type="button" class="start-upload-btn"
                (click)="downloadFromNode(selectedNode)">
                <ion-icon size="small" slot="start" color="success" name="cloud-download-outline"></ion-icon>
                Scarica file
            </ion-item>

            <ion-item button lines="full" [disabled]="selectedNode.type == 'dir'" type="button"
                (click)="remove(selectedNode)">
                <ion-icon size="small" slot="start" color="danger" name="trash-outline"></ion-icon>
                Rimuovi file
            </ion-item>
        </ng-template>


    </context-menu>
    -->

        <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
            <ion-refresher-content>
            </ion-refresher-content>
        </ion-refresher>
    </ion-grid>
</ion-content>

<!-- Footer without a border -->
<ion-footer>
    <ion-toolbar color="light" class="bottom-toolbar " *ngIf="!selectedNode">
        <ion-list class="p-0" color="light">
            <ion-item lines="full" color="light">
                <ion-label>Seleziona un file o una cartella.
                </ion-label>
            </ion-item>

            <!--<p>Benvenuto, da qui potrai effettuare lo scambio di file con la nostra piattaforma documentale.</p>-->
        </ion-list>
    </ion-toolbar>
    <ion-toolbar class="bottom-toolbar" *ngIf="selectedNode">
        <ion-list class="p-0" color="light">
            <ion-item lines="full" color="light">
                <ion-label>Selezionato: <b> {{selectedNode.text}}</b>
                </ion-label>


                <ion-button *ngIf="selectedNode.original.shared_with" size="small" fill="clear" color="dark"
                    id="click-trigger" slot="end">
                    <!--<ion-icon name="people-outline"></ion-icon>-->
                    <ion-icon slot="start" name="people-outline"></ion-icon>
                    condiviso con {{selectedNode.original.shared_with.length}} utenti
                </ion-button>

                <ion-popover *ngIf="selectedNode?.original?.shared_with" trigger="click-trigger" triggerAction="click">
                    <ng-template>
                        <ion-list lines="inset">
                            <ion-item *ngFor="let email of selectedNode.original.shared_with">{{email}}</ion-item>
                        </ion-list>
                    </ng-template>
                </ion-popover>
            </ion-item>

            <ion-item button lines="full">
                <ion-icon slot="start" color="primary" name="cloud-upload-outline"></ion-icon>
                <input onclick="this.value=null" id="input_files" type="file" ngFileSelect [options]="options"
                    (uploadOutput)="onUploadOutput($event, selectedNode)" [uploadInput]="uploadInput" multiple />
            </ion-item>

            <ion-item button lines="full" [disabled]="selectedNode.type == 'dir'" type="button" class="start-upload-btn"
                (click)="downloadFromNode(selectedNode)">
                <ion-icon slot="start" color="success" name="cloud-download-outline"></ion-icon>
                Scarica File
            </ion-item>

            <ion-item button lines="full" [disabled]="selectedNode.type == 'dir'" type="button" class="start-upload-btn"
                (click)="remove(selectedNode)">
                <ion-icon slot="start" color="danger" name="trash-outline"></ion-icon>
                Rimuovi File
            </ion-item>

            <!--
            <ion-item lines="none" >
                <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event, selectedNode)"
                [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
                    <h1>Drag &amp; Drop</h1>
                </div>
            </ion-item>-->

        </ion-list>

    </ion-toolbar>
    <ion-list *ngIf="files_to_upload.length>0">
        <ion-item lines="full" [color]="file.error ? 'warning': ''" *ngFor="let file of files_to_upload">
            <p slot="start">{{file.name}} <span *ngIf="!file.error">{{file.progress.data.percentage | number}}%</span>
            </p>
            <ion-progress-bar *ngIf="!file.error" name="upload_progress" value="{{file.progress.data.percentage/100}}">
            </ion-progress-bar>
            <span>{{file.error}}</span>
            <ion-button slot="end" color="danger" (click)="cancelUpload(file.id); removeFile(file.id);">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-item>

    </ion-list>
</ion-footer>