import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'ngx-easy-table';
import { IonicModule } from '@ionic/angular';
import { LogsPageRoutingModule } from './logs-routing.module';
import { LogsPage } from './logs.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TableModule,
        NgxDatatableModule,
        LogsPageRoutingModule
    ],
    declarations: [LogsPage]
})
export class LogsPageModule { }
