<ion-header class="ion-no-border">
    <ion-toolbar color="none">
        <ion-buttons slot="start">
            <ion-menu-button color="light" menuId="sidenav"></ion-menu-button>
        </ion-buttons>
        <ion-title color="light">Modifica la tua password</ion-title>
    </ion-toolbar>
</ion-header>
<div class="background-image"></div>
<div class="blur-overlay"></div> <!-- Overlay con effetto blur -->

<ion-content fullscreen class="ion-padding">
    <ion-grid class="ion-justify-content-center ion-align-items-center" style="height: 100%;">
        <ion-row class="h-100 ion-justify-content-center">
            <ion-col size="12" size-md="5" class="ion-align-self-center">


                <ion-card class="p-5" style="text-align:center">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">

                        <ion-card-header>
                            <ion-card-title>
                                <ion-img class="w-100" src="/assets/images/logo-motto.png"></ion-img><br>
                            </ion-card-title>
                            <ion-card-subtitle>
                                <ion-label>Modifica la tua password</ion-label>
                            </ion-card-subtitle>
                        </ion-card-header>

                        <div padding>

                            <ion-item class="ion-item-centered">
                                <ion-label for="new-pwd" position="floating">Nuova Password: </ion-label>
                                <ion-input required formControlName="newPwd" id="new-pwd"
                                    type="{{ showPassword ? 'text' : 'password' }}"></ion-input>

                            </ion-item>

                            <ion-item class="ion-item-centered">
                                <ion-label for="conf-pwd" position="floating">Conferma nuova password: </ion-label>
                                <ion-input required formControlName="confPwd" id="conf-pwd"
                                    type="{{ showPassword ? 'text' : 'password' }}"></ion-input>
                                <ion-icon slot="end" [name]="showPassword ? 'eye-off' : 'eye'"
                                    (click)="togglePasswordVisibility()"></ion-icon>
                            </ion-item>
                            <br>

                            <div padding>
                                <ion-button type="submit" [disabled]="!form.valid" expand="block">Conferma
                                </ion-button>
                            </div>

                            <p class="text-danger" *ngIf="form.value.newPwd.length < 12"><b>Deve contenere almeno 12
                                    caratteri
                                    alfanumerici.</b></p>
                            <p class="text-danger" *ngIf="form.value.newPwd !== form.value.confPwd"><b>Le password non
                                    corrispondono.</b>
                            </p>

                        </div>

                    </form>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>