import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.page.html',
    styleUrls: ['./maintenance.page.scss'],
})
export class MaintenancePage {

    constructor(
        private menuController: MenuController) {
        this.menuController.enable(false)
    }
}
