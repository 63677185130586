import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingScreenService } from '../../shared/components/loadingscreen/loadingscreen.service';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private loadingScreen: LoadingScreenService, private toast: ToastService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingScreen.setLoading(true)
        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse)
                    this.loadingScreen.setLoading(false)
            }),
            catchError((err: any) => {

                let errorMsg = ''
                let errorTitle = ''

                if (err.error instanceof ErrorEvent) {
                    console.log('Client side error');
                    errorTitle = 'Client Error'
                    errorMsg = `Error: ${err.error.message}`;
                } else {
                    console.log('Server side error');
                    errorMsg = `Error Code: ${err.status}, ${err.message}`;
                    errorTitle = 'Server Error'

                    if (err.error instanceof Blob) {
                        //this.handleBlobError(error);
                    }

                    if (err.status == 401 || err.status == 403) {
                        errorTitle = "Sessione scaduta"
                        errorMsg = "Si prega di effettuare nuovamente l'accesso."
                        this.auth.logoutUser()
                    }
                }

                this.toast.showMessage(errorTitle, errorMsg, 3000)

                this.loadingScreen.setLoading(false)
                return of(err);
            })
        )
    }
}
