import { ApiService } from '../services/api.service';

export function appInitializer(api: ApiService) {
    return () =>
        new Promise<void>((resolve) => {
            api.me().subscribe({
                error: (err) => { resolve() },
                complete: () => { resolve() }
            });
        });
}
