<ion-header class="ion-no-border">
    <ion-toolbar color="none">
        <ion-buttons slot="start">
            <ion-menu-button color="light" menuId="sidenav"></ion-menu-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<div class="background-image"></div>
<div class="blur-overlay"></div> <!-- Overlay con effetto blur -->

<ion-content fullscreen class="ion-padding">
    <ion-grid class="ion-justify-content-center ion-align-items-center" style="height: 100%;">
        <ion-row class="h-100 ion-justify-content-center">
            <ion-col size="12" sizeXl="4" sizeLg="5" size-md="8" class="ion-align-self-center">
                <ion-row class="ion-justify-content-center">
                    <ion-card style="text-align:center">
                        <ion-card-header>
                            <ion-card-title>
                                <ion-img class="w-100 p-5 pb-2" src="/assets/images/logo.png"></ion-img>

                            </ion-card-title>
                            <ion-card-subtitle><br>Benvenuti sulla <b>Piattaforma Documentale</b>,<br>la
                                soluzione online per lo
                                scambio di
                                documenti digitali.<br>Accedi per iniziare.</ion-card-subtitle>
                        </ion-card-header>

                        <ion-card-content>
                            <form #form="ngForm" (ngSubmit)="login(form.value.email, form.value.password)">
                                <ion-item>
                                    <ion-input aria-label="Email" name="email" type="email" placeholder="Email" ngModel
                                        required>
                                    </ion-input>
                                </ion-item>
                                <br>

                                <ion-item class="ion-item-centered">
                                    <ion-input aria-label="Password" ngModel required name="password"
                                        placeholder="Password"
                                        type="{{ showPassword ? 'text' : 'password' }}"></ion-input>
                                    <ion-icon slot="end" [name]="showPassword ? 'eye-off' : 'eye'"
                                        (click)="togglePasswordVisibility()"></ion-icon>
                                </ion-item>
                                <br>
                                <ion-button type="submit" expand="block">Accedi</ion-button>
                            </form>

                            <ion-text>
                                <p>Per assistenza contattare <a
                                        href="mailto:assistenza@faedis.org">assistenza@faedis.org</a></p>
                            </ion-text>

                            <ion-text color="danger">
                                <p>{{errorMsg}}</p>
                            </ion-text>

                        </ion-card-content>

                    </ion-card>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>