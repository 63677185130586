import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})

export class ToastService {

    constructor(public toastController: ToastController) { }


    async showMessage(title: string, msg: string, time?: number) {
        const toast = await this.toastController.create({
            header: title,
            message: msg,
            color: "primary",
            position: "top",
            duration: time || 2000
        });
        toast.present();
    }

    async showWarning(title: string, msg: string, time?: number) {
        const toast = await this.toastController.create({
            header: title,
            message: msg,
            color: "warning",
            position: "top",
            duration: time || 2000
        });
        toast.present();
    }

    async showSuccess(title: string, msg: string, time?: number) {
        const toast = await this.toastController.create({
            header: title,
            message: msg,
            color: "success",
            position: "top",
            duration: time || 2000
        });
        toast.present();
    }

    async showAlert(title: string, msg: string, time?: number) {
        const toast = await this.toastController.create({
            header: title,
            message: msg,
            color: "danger",
            position: "top",
            duration: time || 2000
        });
        toast.present();
    }

}
