import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminPageModule } from './modules/admin/admin.module';
import { ChangePasswordModule } from './modules/change-password/change-password.module';
import { FolderPageModule } from './modules/folders/folders.module';
import { LoginPageModule } from './modules/login/login.module';
import { MaintenancePageModule } from './modules/maintenance/maintenance.module';
import { IsFirstPasswordChanged, IsUserAdmin, IsUserLogged } from './shared/guards/auth.guard';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'maintenance',
        loadChildren: () => MaintenancePageModule
    },
    {
        path: 'login',
        loadChildren: () => LoginPageModule,
    },
    {
        path: 'change-password',
        canActivate: [IsUserLogged],
        loadChildren: () => ChangePasswordModule
    },
    {
        path: 'folders',
        canActivate: [IsUserLogged, IsFirstPasswordChanged],
        loadChildren: () => FolderPageModule
    },
    {
        path: 'admin',
        canMatch: [IsUserLogged, IsFirstPasswordChanged, IsUserAdmin],
        loadChildren: () => AdminPageModule
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
