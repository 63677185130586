import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { MaintenancePage } from './maintenance.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MaintenanceRoutingModule
    ],
    declarations: [MaintenancePage]
})
export class MaintenancePageModule { }
