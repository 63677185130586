import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../../core/services/auth.service';
@Component({
    selector: 'app-admin',
    templateUrl: './admin.page.html',
    styleUrls: ['./admin.page.scss'],
})
export class AdminPage {

    superAdmin: boolean = false;
    constructor(private authService: AuthService,
        private menuController: MenuController) {
        this.menuController.enable(true)
    }

    ngOnInit() {
        console.log("AdminPage initialized")
        this.authService.user$.subscribe(user => {
            if (user)
                this.superAdmin = user.isAdmin! > 1 ? true : false;
        })
    }

    ngOnDestroy() {
        console.log("AdminPage destroyed")
    }

}
