import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/it'; // Importa il locale italiano

dayjs.extend(relativeTime);
dayjs.locale('it'); // Imposta il locale italiano come locale predefinito

@Pipe({
    name: 'dateAsAgo'
})
export class DateAsAgoPipe implements PipeTransform {


    transform(value: any, ...args: unknown[]): unknown {
        if (!value) { return '-'; }
        // Converti il valore nella data specifica utilizzando dayjs

        const dateValue = dayjs(value, { utc: true });

        // Calcola il tempo trascorso utilizzando dayjs
        const timeAgo = dateValue.fromNow();


        return `${timeAgo}`;
    }

    prettyPrint(value: any, ...args: unknown[]): unknown {
        if (!value) { return '-'; }

        const dateValue = dayjs(value, { utc: true });
        return dateValue.format('DD/MM/YYYY HH:mm:ss');
    }
}
