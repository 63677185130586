import { Component } from '@angular/core';
import { LoadingScreenService } from './loadingscreen.service';

@Component({
    selector: 'app-loadingscreen',
    templateUrl: './loadingscreen.component.html',
    styleUrls: ['./loadingscreen.component.scss'],
})
export class LoadingscreenComponent {

    isLoading: boolean = false

    constructor(private loadingScreenService: LoadingScreenService) {
        this.loadingScreenService.componentMethodCalled$.subscribe(
            (value: boolean) => {
                this.isLoading = value
            }
        );
    }
}
