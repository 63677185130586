<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar color="light">
        <ion-buttons slot="start">
            <ion-menu-button menuId="sidenav"></ion-menu-button>
        </ion-buttons>
        <ion-title>Registro</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div class="table-container" style="height: 70vh;">
        <div>
            <ion-item lines="full">
                <ion-input placeholder="Cerca per email" [(ngModel)]="emailFilter"
                    (ionChange)="filterByEmail()"></ion-input>
            </ion-item>
        </div>
        <ngx-datatable #table id="datatable" class="material" [rows]="rows" [columns]="columns"
            [columnMode]="ColumnMode.flex" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
            [loadingIndicator]="isLoading" [scrollbarV]="true" (scroll)="onScroll($event.offsetY)"
            (tableContextmenu)="onTableContextMenu($event)"></ngx-datatable>
    </div>

    <!-- Footer without a border -->
    <ion-footer style="bottom:0; position:fixed;">
        <ion-list class="p-0" color="light">
            <ion-item lines="full" color="light">
                <ion-label><strong>Email:</strong> {{ contextmenuRow?.email }}</ion-label>
            </ion-item>
            <ion-item lines="full" color="light">
                <ion-label><strong>Time ago:</strong> {{ contextmenuRow?.time_ago }}</ion-label>
            </ion-item>
            <ion-item lines="full" color="light">
                <ion-label><strong>Data:</strong> {{ contextmenuRow?.date }}</ion-label>
            </ion-item>
            <ion-item lines="full" color="light">
                <ion-label><strong>Descrizione:</strong> {{ contextmenuRow?.descrizione }}</ion-label>
            </ion-item>
            <ion-item lines="full" color="light">
                <ion-label><strong>File:</strong> {{ contextmenuRow?.filename }}</ion-label>
            </ion-item>
            <ion-item lines="full" color="light">
                <ion-label><strong>Percorso:</strong> {{ contextmenuRow?.path_lower }}</ion-label>
            </ion-item>
        </ion-list>

    </ion-footer>

</ion-content>