import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})


export class LoadingScreenService {

    // Observable string sources
    private componentMethodCallSource = new Subject<boolean>();

    // Observable string streams
    componentMethodCalled$ = this.componentMethodCallSource.asObservable();

    // Service message commands
    setLoading(value: boolean) {
        this.componentMethodCallSource.next(value);
    }

}
