<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar color="light">
        <ion-buttons slot="start">
            <ion-menu-button menuId="sidenav"></ion-menu-button>
        </ion-buttons>
        <ion-title>Gestione accessi file e cartelle</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid style="height: 100%;">

        <ion-row style="height: 100%;">
            <!-- Colonna sinistra: Foldercomponent View -->
            <ion-col size-md="8" size-sm="12">
                <!-- Includi il componente app-foldertree per visualizzare la struttura delle cartelle -->
                <app-foldertree (selectedNodes)="selectNodes($event)" [options]="ADMIN_TREE_OPTIONS"
                    [folders]="folders">
                </app-foldertree>
            </ion-col>

            <!-- Colonna destra: Menu opzioni -->
            <ion-col size-md="4" size-sm="12" class="right-sidenav">

                <!-- Sezione: Aggiungi Utenti -->
                <div class="menu-elem">
                    <!-- Titolo e sottotitolo -->
                    <ion-card-title>Aggiungi utenti/accessi
                        <span *ngIf="selectedNodeIds.folders.length > 0 || selectedNodeIds.files.length > 0">
                            {{selectedNodeIds.folders.length + selectedNodeIds.files.length }}
                            percorsi selezionati
                        </span>
                    </ion-card-title>
                    <ion-card-subtitle>Condivide con gli utenti i file e le cartelle dei percorsi
                        selezionati.</ion-card-subtitle>
                    <!-- Dropdown per selezionare gli utenti -->
                    <ng-multiselect-dropdown [placeholder]="'Utenti'" [settings]="usersDropdownSettings" [data]="users"
                        [(ngModel)]="selectedEmails"></ng-multiselect-dropdown>
                    <!-- Aggiungi email e pulsante per aggiungere una nuova email -->
                    <ion-item class="ion-align-items-center">
                        <ion-input aria-label="Aggiungi un'email" [(ngModel)]="newUsrEmail" type="email"
                            placeholder="Aggiungi un'email"></ion-input>
                        <ion-button (click)="addNewUserEmail(newUsrEmail)" fill="clear" slot="end">
                            <ion-icon name="add-outline"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <!-- Mostra le email selezionate con pulsante per rimuoverle -->

                    <!--<ion-item *ngFor="let obj of selectedEmails" class="ion-align-items-center selected-email-item">
                        <ion-badge>{{ obj.email }}</ion-badge>
                        <ion-button fill="clear" (click)="removeSelectedEmail(obj.email)">
                            <ion-icon name="close-circle"></ion-icon>
                        </ion-button>
                    </ion-item> -->

                    <!-- Altre opzioni -->
                    <br>
                    <ng-multiselect-dropdown [placeholder]="'Azienda'" [settings]="aziendeDropdownSettings"
                        [data]="aziende" [(ngModel)]="selectedAzienda"></ng-multiselect-dropdown>
                    <br>
                    <ng-multiselect-dropdown [placeholder]="'Allegato'" [settings]="attachmentDropdownSettings"
                        [data]="attachments" [(ngModel)]="selectedAttachment"></ng-multiselect-dropdown>
                    <br>
                    <ion-item>Invia email
                        <ion-checkbox aria-label="Invia email" [(ngModel)]="selectedSendEmail" slot="end"
                            checked="false"></ion-checkbox>
                    </ion-item>
                    <ion-item>Write access
                        <ion-checkbox aria-label="Write access" [(ngModel)]="selectedWriteAccess" slot="end"
                            checked="true"></ion-checkbox>
                    </ion-item>
                    <!-- Pulsante per aggiungere utenti -->
                    <ion-item lines="none">
                        <ion-button slot="end"
                            (click)="addUsers(selectedEmails, selectedWriteAccess, selectedAttachment, selectedAzienda, selectedSendEmail )"
                            color="light" type="submit"> Aggiungi
                        </ion-button>
                    </ion-item>
                </div>

                <!-- Sezione: Cerca i file e le cartelle di un Utente -->
                <div class="menu-elem">
                    <!-- Titolo e sottotitolo -->
                    <ion-card-title>Cerca cartelle utente</ion-card-title>
                    <ion-card-subtitle>Tutte le cartelle condivise con un utente.</ion-card-subtitle>
                    <!-- Dropdown per selezionare un utente -->
                    <ng-multiselect-dropdown [placeholder]="'Utenti'" [settings]="singleUserDropdownSettings"
                        [data]="users" [(ngModel)]="searchSelectedId"></ng-multiselect-dropdown>
                    <!-- Pulsante per avviare la ricerca -->
                    <ion-item lines="none">
                        <ion-button slot="end" (click)="searchUser(searchSelectedId[0].user_id)" color="light"
                            type="submit">
                            Cerca
                        </ion-button>
                    </ion-item>
                </div>

                <!-- Sezione: Rimuovi Utenti -->
                <div class="menu-elem">
                    <!-- Titolo e sottotitolo -->
                    <ion-card-title>Rimuovi accessi <span
                            *ngIf="selectedNodeIds.folders.length > 0 || selectedNodeIds.files.length > 0">
                            da {{selectedNodeIds.folders.length + selectedNodeIds.files.length }}
                            percorsi selezionati</span>
                    </ion-card-title>
                    <ion-card-subtitle>Rimuove gli accessi degli utenti ai percorsi selezionati.
                    </ion-card-subtitle>
                    <!-- Dropdown per selezionare un utente da rimuovere -->
                    <ng-multiselect-dropdown [placeholder]="'Utenti'" [settings]="singleUserDropdownSettings"
                        [data]="users" [(ngModel)]="deleteSelectedId"></ng-multiselect-dropdown>
                    <!-- Pulsante per avviare la rimozione -->
                    <ion-item lines="none">
                        <ion-button slot="end" (click)="deleteUser(deleteSelectedId[0].user_id)" color="light"
                            type="submit">
                            Rimuovi
                        </ion-button>
                    </ion-item>
                </div>

                <!-- Sezione: Reset credenziali Utente -->
                <div class="menu-elem">
                    <!-- Titolo e sottotitolo -->
                    <ion-card-title>Reset credenziali</ion-card-title>
                    <ion-card-subtitle>Resetta le credenziali di un utente.</ion-card-subtitle>
                    <!-- Dropdown per selezionare un utente da cui resettare le credenziali -->
                    <ng-multiselect-dropdown [placeholder]="'Utenti'" [settings]="singleUserDropdownSettings"
                        [data]="users" [(ngModel)]="resetSelectedId"></ng-multiselect-dropdown>
                    <!-- Pulsante per avviare il reset delle credenziali -->
                    <ion-item lines="none">
                        <ion-button slot="end" (click)="resetUser(resetSelectedId[0].user_id)" color="light"
                            type="submit">
                            Reset
                        </ion-button>
                    </ion-item>
                </div>

            </ion-col>
        </ion-row>

    </ion-grid>
</ion-content>