import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem(environment.token_id);
        const isApiUrl = req.url.startsWith(environment.serverUrl);

        if (accessToken && isApiUrl) {
            req = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
            });
        }

        return next.handle(req);

    }
}
