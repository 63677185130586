import { AfterViewInit, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import 'jstree';
import { Subject } from 'rxjs';
import { TreeItem } from '../../../core/models/TreeItem';
import { DateAsAgoPipe } from '../../pipes/date-as-ago.pipe';
import { JSTreeItem } from './JSTreeNode.interface';


@Component({
    selector: 'app-foldertree',
    templateUrl: './foldertree.component.html',
    styleUrls: ['./foldertree.component.scss'],
})

export class FoldertreeComponent implements AfterViewInit {

    @ViewChild('jstree') jstreeDom?: JSTree

    @Input() options?: any;
    @Input() folders?: JSTreeItem[]
    @Input() displaySearch?: boolean = true
    @Output() selectedNode = new EventEmitter();
    @Output() selectedNodes = new EventEmitter();

    dateFormatPipeFilter = new DateAsAgoPipe();
    //private jstree_ref?: JSTree;

    //<ion-icon name=\"help-circle-outline\"></ion-icon>
    private jstree_options: any = {
        plugins: ['json_data', 'types', 'sort', 'search', 'wholerow', 'grid'],
        "search": {
            "case_sensitive": false,
            "show_only_matches": true
        },
        grid: {
            columns: [
                {
                    width: '100%',
                    maxWidth: '300px',
                    height: '100%',
                    header: "Nome",
                    title: "Nome"
                },
                {
                    width: '120px',
                    height: 300,
                    header: "Condiviso con",
                    title: "Condiviso con",
                    value: ((node: JSTreeItem) => {
                        if (node.original.shared_with) {
                            let str = '' + node.original.shared_with.length
                            if (node.original.shared_with.length > 1)
                                str += ' utenti'
                            else
                                str += ' utente'
                            return str
                        } else {
                            return '-'
                        }
                    })
                },
                {
                    width: '150px',
                    height: '100%',
                    header: "Aggiornato",
                    title: "Aggiornato",
                    value: ((node: JSTreeItem) => this.dateFormatPipeFilter.transform(node.original.updated_at))
                }
            ],
            width: '100%',
            height: '100%'
        },
        ui: {
            'select_limit': 1
        },
        core: {
            'keyboard': {
                'up': false,
                'down': false,
                'left': false,
                'right': false,
                'space': false,
                'enter': false,
                'return': false
            },
            'check_callback': true,
            'multiple': false,
            'themes': {
                //'name': 'proton',
                //'stripes': true,
                'variant': 'large'
            }
        },
        types: {
            "dir": {
                "icon": "jstree-icon jstree-folder"
            },
            "zip": {
                "icon": "jstree-icon jstree-zip"
            },
            "pdf": {
                "icon": "jstree-icon jstree-pdf"
            },
            "doc": {
                "icon": "jstree-icon jstree-doc"
            },
            "docx": {
                "icon": "jstree-icon jstree-doc"
            },
            "xls": {
                "icon": "jstree-icon jstree-xls"
            },
            "xlsx": {
                "icon": "jstree-icon jstree-xls"
            },
            "new_file": {
                "icon": "jstree-icon jstree-new_file"
            },
            "default": {
                "icon": "jstree-icon jstree-file"
            }
        }
    }



    onFoldersChanges = new Subject<SimpleChanges>();

    ngOnDestroy() {
        $('#jstree').jstree('destroy');
        this.jstreeDom!.destroy();
        this.onFoldersChanges.complete();
    }

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        console.log("FolderTreeComponent: ngOnChanges")
        this.onFoldersChanges.next(changes);
    }

    ngAfterViewInit() {
        console.log("FolderTreeComponent: ngAfterViewInit")

        this.jstreeDom = $('#jstree') as JSTree


        if (this.options) {
            this.jstree_options.plugins.push(...this.options.JSTREE_OPTIONS.plugins)
            this.jstree_options.core.multiple = this.options.JSTREE_OPTIONS.core.multiple ? this.options.JSTREE_OPTIONS.core.multiple : false
            this.jstree_options.checkbox = this.options.JSTREE_OPTIONS.checkbox
        }

        // Create an instance when the DOM is ready
        this.jstreeDom!.jstree(this.jstree_options)
        //this.jstreeDom!.show_stripes()

        // Bind to events triggered on the tree
        this.jstreeDom!.on("changed.jstree", (e, data) => {
            this.selectedNodes.emit(data.selected)
            this.selectedNode.emit($('#jstree').jstree('get_node', data.selected[0]));
        });


        /*
        $('#jstree').on("ready.jstree", (e, data)=> {
            //console.log("Ready")
            var tree = data.instance;
            var obj = tree.get_selected(true)[0];

            // trigger the on_select handler for the tree node that is currently selected and ensure that it is opened
            if (obj) {
                if (obj.state.opened) {
                    tree.trigger('select_node', { 'node': obj, 'selected': tree._data.core.selected, 'event': e });
                } else {
                    tree.open_node(obj, function () {
                        tree.trigger('select_node', { 'node': obj, 'selected': tree._data.core.selected, 'event': e });
                    });
                }
            }
        })
        */

        // Interact with the tree
        /*
        $('button').on('click', () => {
            $('#jstree').jstree(true).select_node('child_node_1');
            $('#jstree').jstree('select_node', 'child_node_1');
            $.jstree?.reference('#jstree').select_node('child_node_1');
        });*/

        // Aggiorna l'albero se avvengon modifiche alla variabile folders
        this.onFoldersChanges.subscribe(async (changes: SimpleChanges) => {
            let now = new Date()
            const tree = await this.listToTree(changes.folders.currentValue)
            console.log("*****Time to convert list to tree: ", new Date().getTime() - now.getTime())
            if (this.jstreeDom) {
                this.jstreeDom.jstree(true).settings!.core.data = tree
                console.log("*****Time to set tree: ", new Date().getTime() - now.getTime())

                this.jstreeDom.jstree(true).refresh()
                console.log("*****Time to refresh tree: ", new Date().getTime() - now.getTime())
            }
        });

    }

    getSelectedNode() {
        const node_id = $('#jstree').jstree('get_selected')[0];
        return this.getNodeById(node_id);
    }

    getNodeById(node_id: string): JSTreeItem {
        return $('#jstree').jstree('get_node', node_id);
    }

    getOriginalNodeById(node_id: string): TreeItem {
        let js_node = this.getNodeById(node_id)
        return js_node.original
    }

    searchNode($searchString: string) {
        console.log($searchString)
        $('#jstree').jstree('search', $searchString);
    }

    private listToTree(list: TreeItem[]): TreeItem[] {
        let map: { [key: string]: TreeItem } = {}, node: TreeItem, roots: TreeItem[] = [], i: number;

        // Inizializza la mappa con ogni item usando path_lower come chiave
        // Rimuovo il prefisso /ffaedi-documentale
        for (i = 0; i < list.length; i++) {
            list[i].path_lower = list[i].path_lower.split('/').slice(2).join('/')
            list[i].path_display = list[i].path_display.split('/').slice(2).join('/')

            map[list[i].path_lower] = list[i];
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];

            // Aggiungi informazioni aggiuntive al nodo come necessario
            const extension = node.filename ? node.filename.split('.').pop() : 'dir';
            node.type = extension;
            node.file_id = node.file_id ? node.file_id : undefined;
            node.folder_id = node.folder_id ? node.folder_id : undefined;
            node.text = node.path_display.split('/').pop()
            node.path = node.path_lower;
            node.a_attr = { title: node.text };

            const parentPath = node.path_lower.substring(0, node.path_lower.lastIndexOf('/'));
            if (map[parentPath]) {
                // Aggiungi il nodo corrente ai suoi figli solo se il nodo padre esiste
                map[parentPath].children!.push(node);
            } else {
                // Se non c'è un nodo padre, è una radice
                roots.push(node);
            }
        }
        return roots;

    }


    addFileToTree(parent_node: JSTreeItem, item: TreeItem) {
        let newNode = {
            ...item,
            text: item.filename,
            type: 'new_file',
            state: {
                opened: true,
                disabled: false,
                selected: false
            }
        }

        //Rimuovo il nodo se esiste già nello stesso livello
        parent_node.children.findIndex((children_id: string) => {
            const node = this.getNodeById(children_id);
            if (node.text === item.filename) {
                this.removeItem(node);
                return true;
            }
        });

        //Aggiungo il nuovo nodo
        $('#jstree').jstree().create_node(parent_node.id, newNode);
        $("#jstree").jstree().open_node(parent_node.id);
    }

    removeItem(node: JSTreeItem) {
        $('#jstree').jstree("delete_node", node);
    }
}
