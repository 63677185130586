export const environment = {
	production: true,
	USER_TYPE: {
		SUPER_ADMIN: 2,
		ADMIN: 1,
		USER: 0
	},
	serverUrl: 'https://server-app.faedis.org',
	token_id: 'ffaedi_token',
	//Mb
	MAX_FILE_SIZE_UPLOAD: 300,
	MAX_FILE_NUMBER_UPLOAD: 50,
	maintenance: false,
};
