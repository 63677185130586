import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TooltipModule } from '../tooltips/tooltip.module';
import { FoldertreeComponent } from './foldertree.component';


@NgModule({
    declarations: [
        FoldertreeComponent
    ],
    imports: [
        CommonModule,
        TooltipModule,
        IonicModule
    ],
    exports: [
        FoldertreeComponent
    ]
})

export class FoldertreeModule { }
