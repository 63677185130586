import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
//import { Columns, Config, DefaultConfig } from 'ngx-easy-table';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { ApiService } from '../../../../core/services/api.service';
import { LoadingScreenService } from '../../../../shared/components/loadingscreen/loadingscreen.service';
import { DateAsAgoPipe } from '../../../../shared/pipes/date-as-ago.pipe';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.page.html',
    styleUrls: ['./logs.page.scss'],
})
export class LogsPage implements AfterViewInit {
    @ViewChild('table', { read: ElementRef, static: false }) tableView!: ElementRef;

    readonly headerHeight = 50;
    readonly rowHeight = 50;
    readonly pageLimit = 30;

    ColumnMode = ColumnMode;
    isLoading = false;
    isLast = false;

    emailFilter?: string;

    columns = [
        { prop: 'time_ago', flexGrow: 1 },
        { name: 'date', flexGrow: 1 },
        { name: 'email', flexGrow: 2 },
        { name: 'descrizione', flexGrow: 3 },
        { name: 'filename', flexGrow: 4 },
    ];
    rows: any[] = [];

    rawEvent: any;
    contextmenuRow: any;
    contextmenuColumn: any;

    dateFormatPipeFilter = new DateAsAgoPipe();

    constructor(
        private api: ApiService,
        private loadingScreenService: LoadingScreenService
    ) { }

    ngAfterViewInit() {
        this.initialLoad();
    }

    filterByEmail() {
        this.resetDataAndScroll();
    }

    onScroll(offsetY: number) {
        const viewHeight = this.tableView.nativeElement.offsetHeight;

        if (!this.isLoading && !this.isLast && offsetY + viewHeight >= this.rows.length * this.rowHeight) {
            const limit = this.calculateLimit(viewHeight);
            this.loadPage(limit);
        }
    }

    private initialLoad() {
        this.onScroll(0);
    }

    private resetDataAndScroll() {
        this.rows = [];
        this.isLast = false;
        this.onScroll(0);
    }

    private calculateLimit(viewHeight: number): number {
        if (this.rows.length === 0) {
            const pageSize = Math.ceil(viewHeight / this.rowHeight);
            return Math.max(pageSize, this.pageLimit);
        }
        return this.pageLimit;
    }

    private loadPage(limit: number) {
        if (!this.isLast) {
            this.setLoading(true);

            this.api.getLog(this.rows.length, limit, this.emailFilter).subscribe({
                next: (res) => {
                    const data = this.transformData(res.data);

                    const rows = [...this.rows, ...data];
                    this.rows = rows;
                    this.isLast = res.isLast;
                },
                error: (err) => console.log(err),
                complete: () => this.setLoading(false),
            });
        }
    }

    private setLoading(loading: boolean) {
        this.isLoading = loading;
        this.loadingScreenService.setLoading(loading);
    }

    private transformData(data: any[]): any[] {
        return data.map((row: any) => {
            row.time_ago = this.dateFormatPipeFilter.transform(row.created_at);
            row.date = this.dateFormatPipeFilter.prettyPrint(row.created_at);
            return row;
        });
    }

    onTableContextMenu(contextMenuEvent: any) {
        this.rawEvent = contextMenuEvent.event;
        if (contextMenuEvent.type === 'body') {
            this.contextmenuRow = contextMenuEvent.content;
            this.contextmenuColumn = undefined;
        } else {
            this.contextmenuColumn = contextMenuEvent.content;
            this.contextmenuRow = undefined;
        }

        contextMenuEvent.event.preventDefault();
        contextMenuEvent.event.stopPropagation();
    }
}
