import { UploadInput, UploadOutput, UploaderOptions } from '@angular-ex/uploader';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { TreeItem } from '../../core/models/TreeItem';
import { ApiService } from '../../core/services/api.service';
import { ToastService } from '../../core/services/toast.service';
import { JSTreeItem } from '../../shared/components/foldertree/JSTreeNode.interface';
import { JSTreeOptions } from '../../shared/components/foldertree/JSTreeOptions.interface';
import { FoldertreeComponent } from '../../shared/components/foldertree/foldertree.component';
import { LoadingScreenService } from '../../shared/components/loadingscreen/loadingscreen.service';
import { MyUploadFile } from './MyUploadFile';

@Component({
    selector: 'app-folders',
    templateUrl: './folders.page.html',
    styleUrls: ['./folders.page.scss'],
})
export class FoldersPage implements AfterViewInit {

    @ViewChild(FoldertreeComponent) folderTree: FoldertreeComponent;

    CLIENT_TREE_OPTIONS: JSTreeOptions = {
        JSTREE_OPTIONS: {
            plugins: [],
            core: {
                expand_selected_onload: true,
                multiple: false,
                error: () => {
                    console.log("JSTREE Errore")
                }
            }
        },
        adminTree: false
    }

    //Js Tree folders
    folders: TreeItem[] = [];


    //File uploader
    options: UploaderOptions = {
        concurrency: 1,
        maxUploads: environment.MAX_FILE_NUMBER_UPLOAD,
        maxFileSize: environment.MAX_FILE_SIZE_UPLOAD * 1000000
    }
    files_to_upload: MyUploadFile[] = [];
    uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    private humanizeBytes?: any;
    private dragOver?: boolean;
    selectedNode?: JSTreeItem;
    private share_link_url?: string;


    constructor(
        private loadingScreen: LoadingScreenService,
        private api: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private menuController: MenuController) {
        //    this.humanizeBytes = humanizeBytes;
        this.folderTree = <FoldertreeComponent><unknown>import('../../shared/components/foldertree/foldertree.component');
        this.menuController.enable(true)
    }
    ngOnInit() {
        let download_pathname = this.route.snapshot.queryParamMap.get('download');
        if (download_pathname) {
            download_pathname = decodeURIComponent(download_pathname)
            //this.downloadFromLink(download_pathname);
        }
    }

    ngAfterViewInit(): void {
        this.api.getTree().subscribe({
            next: (folders: TreeItem[]) => {
                this.folders = folders;
            },
            error: (err) => {
                console.error("Error while getting user folders"); console.error(err)
            }
        })
    }

    onSelectedNodeChanges(node: JSTreeItem) {
        this.selectedNode = node;
    }


    /*
    downloadFromLink(file_id: string) {
        this.toastService.showMessage("Richiesta file", "Richiesta del file id:" + file_id + " in corso..")

        this.downloadFile(file_id).add(
            () => {
                //Clear query params
                this.router.navigate(
                    [],
                    { replaceUrl: true });
            }
        )
    }
    */

    /*
    createLink(node: JSTreeItem) {
        const query_component = encodeURIComponent(node.original.path!)
        this.share_link_url = encodeURI(environment.baseUrl + "/folders?download=" + query_component)

        // Copy the text inside the text field
        navigator.clipboard.writeText(this.share_link_url);
        console.log(this.share_link_url)
        // Alert the copied text
        this.toastService.showSuccess("Link copiato", "Il link è stato copiato negli appunti", 3000);
    }
    */

    downloadFromNode(node: JSTreeItem) {
        console.log(node)
        if (node.type != 'dir') {
            this.downloadFile(node);
        } else {
            this.toastService.showWarning("Attenzione", "È possibile scaricare soltanto singoli files.", 3000)
        }
    }

    downloadFile(node: JSTreeItem) {
        return this.api.downloadFile(node.original.file_id!).subscribe({
            next: (data) => {
                saveAs(data, node.text);
            },
            error: (err: HttpErrorResponse) => {
                console.log(err)
            }
        })
    }

    remove(node: JSTreeItem) {
        console.log(node)
        if (node.type != 'dir') {

            if (confirm("Vuoi eliminare '" + node.text + "' ?")) {

                this.toastService.showMessage("Attendere", "Operazione in corso..", 2000);

                //path = path.substring(0, path.length - filename.length - 1)
                this.api.removeFile(node.original.file_id!).subscribe({
                    next: (res: any) => {
                        this.folderTree.removeItem(node);
                        this.toastService.showSuccess(node.text, "Rimosso correttamente.", 3000);
                    },
                    error: (err) => {
                        console.log(err)
                        this.toastService.showAlert("Errore", err.error, 3000)
                    }
                });
            }
        }
    }

    onUploadOutput(output: UploadOutput, parent_node: any) {
        switch (output.type) {
            case 'rejected':
                this.toastService.showWarning(output.file!.name, "Il file non deve superare i " + environment.MAX_FILE_SIZE_UPLOAD + "Mb oppure il formato non è valido.", 3000)
                break;
            case 'allAddedToQueue':
                let currId = this.selectedNode?.original.folder_id
                //console.log(this.selectedNode)
                //Se è selezionato un file recupero il parent id
                if (this.selectedNode!.type != 'dir')
                    currId = this.selectedNode?.original.parent_id

                this.startUpload(currId!);

                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined')
                    this.files_to_upload.push(output.file);
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files_to_upload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files_to_upload[index] = output.file;
                    //console.log(output.file.progress.data!.percentage)
                }
                this.loadingScreen.setLoading(true)

                break;
            case 'removed':
                // remove file from array when removed
                this.files_to_upload = this.files_to_upload.filter((file: MyUploadFile) => file !== output.file);
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'done':
                this.loadingScreen.setLoading(false)
                // The upload is ended
                if (output.file!.responseStatus == 200) {
                    if (parent_node.type != 'dir') {
                        parent_node = this.folderTree.getNodeById(parent_node.parents[0]);
                    }

                    const file = output.file?.response[0]
                    console.log(file)
                    this.folderTree.addFileToTree(parent_node, file)

                    const index = this.files_to_upload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files_to_upload.splice(index, 1);
                    this.toastService.showSuccess(output.file!.name, "Caricato correttamente.", 3000);

                } else if (output.file!.responseStatus == 413) {

                    var msg = "Il file non deve superare i " + environment.MAX_FILE_SIZE_UPLOAD + "Mb.";
                    const index = this.files_to_upload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files_to_upload[index].error = msg

                    this.toastService.showWarning(output.file!.name, msg, 3000)
                } else {
                    var msg = "Non è possibile caricare file in questa cartella.";
                    const index = this.files_to_upload.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files_to_upload[index].error = msg
                    this.toastService.showWarning(output.file!.name, msg, 3000)
                }

                break;
        }
    }

    startUpload(folder_id: string) {
        var token = localStorage.getItem(environment.token_id);

        const event: UploadInput = {
            type: 'uploadAll',
            headers: { 'Authorization': 'Bearer ' + token },
            url: environment.serverUrl + '/api/me/tree',
            method: 'POST',
            data: { folder_id: folder_id }
        };

        this.uploadInput.emit(event);
    }

    cancelUpload(id: string) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string) {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles() {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    doRefresh(event: any) {
        this.api.getTree().subscribe({
            next: (folders: TreeItem[]) => this.folders = folders,
            error: (err) => { console.error("Error while getting user folders"); console.error(err) },
            complete: () => event.target.complete()
        })
    }

    private basename(str: string) {
        return str.substring(str.lastIndexOf('/') + 1);
    }
}
