
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs';
import { User } from '../../core/models/User';
import { AuthService } from '../../core/services/auth.service';
import { ToastService } from '../../core/services/toast.service';


/**
 * Check if user is logged in
 * @returns true if user is logged
 */
export const IsUserLogged = () => {

    const authService = inject(AuthService);
    const router = inject(Router);
    const toastService = inject(ToastService);

    if (authService.isLoggedIn) {
        return true
    }

    toastService.showAlert("Attenzione", "È necessario effettuare il login per accedere.", 3000);
    return router.parseUrl('/login');
}

/**
 * Check if user is admin
 * @returns true if user is admin
 */
export const IsUserAdmin = () => {

    const authService = inject(AuthService);
    const router = inject(Router);
    const toastService = inject(ToastService);

    return authService.user$.pipe(
        map((user: User | null) => {
            if (user!.isAdmin) {
                return true
            } else {
                toastService.showAlert("Unauthorized", "", 3000);
                return router.parseUrl('/login');
            }
        }),
        catchError((err: any): any => {
            console.log(err)
            toastService.showAlert("Unauthorized", "", 3000);
            return router.parseUrl('/login');
        })
    )
}

/**
 * Check if user is super admin
 * @returns true if user is super admin
 */
export const IsUserSuperAdmin = () => {
    const authService = inject(AuthService);
    return authService.user$.pipe(
        map((user: User | null) => {
            if (user!.isAdmin! === 2) {
                return true
            } else {
                return false
            }
        }),
        catchError((err: any): any => {
            console.log(err)
            return false
        })
    )
}

/**
 * Check if user is logged and has already changed password
 * @returns true if user is logged and has already changed password
 */
export const IsFirstPasswordChanged = () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.user$.pipe(
        map(
            (user: User | null) => {
                if (user) {
                    if (user.isFirstPasswordChanged) {
                        return true
                    } else {
                        return router.parseUrl('/change-password')
                    }
                } else {
                    return router.parseUrl('/login')
                }

            }),
        catchError((err: any): any => {
            console.log(err)
            return router.parseUrl('/login');
        })
    )

}

