import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TreeItem } from '../../../../core/models/TreeItem';
import { User } from '../../../../core/models/User';
import { ApiService } from '../../../../core/services/api.service';
import { ToastService } from '../../../../core/services/toast.service';
import { JSTreeOptions } from '../../../../shared/components/foldertree/JSTreeOptions.interface';
import { FoldertreeComponent } from '../../../../shared/components/foldertree/foldertree.component';
import { AlertService } from '../../../../core/services/alert.service';

@Component({
    selector: 'app-gestioneAccessi',
    templateUrl: './gestioneAccessi.page.html',
    styleUrls: ['./gestioneAccessi.page.scss'],
})
export class GestioneAccessiPage implements AfterViewInit {

    /**
     * Dropdown settings
     */
    usersDropdownSettings: IDropdownSettings = {};
    aziendeDropdownSettings: IDropdownSettings = {};
    attachmentDropdownSettings: IDropdownSettings = {};
    singleUserDropdownSettings: IDropdownSettings = {};

    /**
     * JSTree Admin settings
     */
    @ViewChild(FoldertreeComponent) folderTree?: FoldertreeComponent;
    ADMIN_TREE_OPTIONS: JSTreeOptions = {
        JSTREE_OPTIONS: {
            plugins: ['checkbox'],
            core: {
                multiple: true,
                error: () => {
                    console.log("JSTREE Errore")
                }
            },
            'checkbox': {
                three_state: false,
                cascade: 'down'
            }
        },
        adminTree: true
    }

    /**
     * Variabile contenente gli id di file e cartelle selezionati del folderTree
     */
    selectedNodeIds: { folders: string[]; files: string[]; } = {
        folders: [],
        files: []
    }
    selectedEmails: any[] = []
    newUsrEmail: string = '';
    selectedEmail!: { email: string };
    selectedAzienda!: any;
    selectedAttachment!: any;
    selectedSendEmail: boolean = false;
    selectedWriteAccess: boolean = true

    /**
     * Variabili Ricerca e Rimozione
     */
    searchSelectedId!: any;
    deleteSelectedId!: any;
    resetSelectedId!: any;

    /**
     * Models ricevuti dal server
     */
    folders: TreeItem[] = [];
    log: any[] = [];
    users: User[] = [];
    user_emails: string[] = [];
    aziende: any[] = [];
    attachments: any[] = [];


    constructor(private api: ApiService,
        private toastService: ToastService,
        private alertService: AlertService) {
    }

    ngOnInit() {
        this.usersDropdownSettings = {
            singleSelection: false,
            idField: 'email',
            textField: 'email',
            selectAllText: 'Seleziona tutto',
            unSelectAllText: 'Deseleziona',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Cerca'
        };

        this.aziendeDropdownSettings = {
            singleSelection: true,
            enableCheckAll: false,
            closeDropDownOnSelection: true,
            idField: 'azienda_id',
            textField: 'ragione_sociale',
            selectAllText: 'Seleziona tutto',
            unSelectAllText: 'Deseleziona',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Cerca'
        }

        this.attachmentDropdownSettings = {
            singleSelection: true,
            closeDropDownOnSelection: true,
            enableCheckAll: false,
            idField: 'file_id',
            textField: 'filename',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Cerca'
        }


        this.singleUserDropdownSettings = {
            singleSelection: true,
            closeDropDownOnSelection: true,
            enableCheckAll: false,
            idField: 'user_id',
            textField: 'email',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            searchPlaceholderText: 'Cerca'
        }
    }

    ngAfterViewInit() {

        console.log("gestioneAccessi: ngAfterViewInit")
        this.api.admin().subscribe({
            next: (res) => {
                console.log(res)
                this.folders = res.folders;
                this.log = res.log;
                this.users = res.users
                this.user_emails = res.users.map((item: { email: string; }) => item.email)
                this.aziende = res.aziende
                this.attachments = res.attachments
            },
            error: (err) => {
                console.log(err)
            }
        })
    }

    ngOnDestroy() {
        console.log("gestioneAccessi: ngOnDestroy")
    }

    /**
     * Aggiunge utenti ai percorsi selezionati
     * @param $selectedNodeIds lista degli id di folders e files da condividere
     * @param $selectedEmails le emails selezionate
     * @param $selectedWriteAccess flag per l'accesso in scrittura alle cartelle
     * @param $selectedAttachment id dell'allegato da inviare via email
     * @param $selectedAzienda id dell'azienda a cui aggiungere i nuovi utenti
     */
    addUsers($selectedEmails: any[], $selectedWriteAccess: boolean, $selectedAttachment: any[], $selectedAzienda: any[], $selectedSendEmail: boolean) {

        $selectedEmails = [...new Set($selectedEmails.map(item => item.email))];

        if ($selectedAzienda)
            $selectedAzienda = $selectedAzienda[0].azienda_id

        if ($selectedAttachment)
            $selectedAttachment = $selectedAttachment[0].file_id
        $selectedWriteAccess = $selectedWriteAccess ? $selectedWriteAccess : false

        let body_data = {
            folders: this.selectedNodeIds.folders,
            files: this.selectedNodeIds.files,
            emails: $selectedEmails,
            permission_write: $selectedWriteAccess,
            azienda_id: $selectedAzienda,
            attachment: $selectedAttachment,
            sendEmail: $selectedSendEmail
        }

        console.log(body_data)

        this.alertService.confirm("Confermi di aggiungere " + body_data.emails.length + " nuovi accessi?", () => {

            this.api.addUsers(body_data).subscribe({
                next: (res) => {
                    console.log(res)
                    this.toastService.showSuccess("Successo", "Tutti gli utenti sono stati aggiunto ai percorsi selezionati.", 3000)
                },
                error: (err) => {
                    console.log(err)
                    this.toastService.showAlert("Errore", "Controlla la console per maggiori dettagli", 3000)
                }
            })
        })

    }

    /**
     * Aggiunge un email alla lista delle email selezionate
     * @param email Email
     */
    addNewUserEmail(email: string) {
        let newEmail = [{ email: email }]
        this.selectedEmails = [...this.selectedEmails, ...newEmail]

    }

    removeSelectedEmail(email: any) {
        console.log(email)
        console.log(this.selectedEmails)
        this.selectedEmails = this.selectedEmails.filter(item => item.email !== email)
    }


    /**
     * Ricerca le cartelle condivise con l'utente $email
     */
    searchUser($user_id: string) {
        if ($user_id) {
            this.api.getUserTree($user_id).subscribe({
                next: (res: TreeItem[]) => this.folders = res,
                error: (err) => console.log(err)
            })
        } else {
            this.getRootTree()
        }
    }

    /**
     * Rimuove l'accesso dell'utente $id alle cartelle selezionate
     */
    deleteUser($user_id: string) {
        if ($user_id) {
            this.alertService.confirm("Sei sicuro di voler rimuovere l'accesso dell'utente?", () => {
                this.api.removeIdsFromUser($user_id, this.selectedNodeIds.folders, this.selectedNodeIds.files).subscribe({
                    next: (res) => {
                        console.log(res)
                        this.toastService.showSuccess("Successo", "Tutti i percorsi sono stati rimossi dall'utente.", 3000)
                    },
                    error: (err) => {
                        console.log(err)
                        this.toastService.showAlert("Errore", "Controlla la console per maggiori dettagli", 3000)
                    },
                    complete: () => {
                        this.searchUser($user_id)
                    }
                })
            })
        }
    }

    resetUser($user_id: string) {
        if ($user_id) {

            this.alertService.confirm("Sei sicuro di voler resettare le credenziali dell'utente?", () => {

                this.api.resetUser($user_id).subscribe({
                    next: (res) => {
                        console.log(res)
                        this.toastService.showSuccess("Successo", "Le credenziali dell'utente sono state resettate.", 3000)
                    },
                    error: (err) => {
                        console.error(err)
                        this.toastService.showAlert("Errore", "Controlla la console per maggiori dettagli", 3000)
                    }
                })
            }
            )
        }
    }

    /**
     * Restituisce tutto l'albero delle cartelle
     */
    getRootTree() {
        this.api.getRootTree().subscribe({
            next: (res: TreeItem[]) => this.folders = res,
            error: (err) => console.log(err)
        })
    }

    /**
     * Funione che seleziona l'oggetto TreeItem dall'albero delle cartelle
     */
    selectNodes($event: any[]) {
        let folders: string[] = []
        let files: string[] = []
        $event.map(node_id => {
            let original_node = this.folderTree!.getOriginalNodeById(node_id);
            if (original_node.folder_id)
                folders.push(original_node.folder_id)
            else
                files.push(original_node.file_id!)
        })
        this.selectedNodeIds = {
            folders, files
        }
    }

    /**
     * Se viene selezionato l'allegato, in automatico viene cliccato anche "Invia Email"
     */
    onSelectedAttacchmentChange($event: any) {
        this.selectedAttachment = $event
        if (this.selectedAttachment)
            this.selectedSendEmail = true
        else
            this.selectedSendEmail = false
    }
}
