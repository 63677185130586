import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { appInitializer } from './core/interceptors/app-initializer';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { MyHttpInterceptor } from './core/interceptors/http-interceptor';
import { ApiService } from './core/services/api.service';
import { LoadingscreenModule } from './shared/components/loadingscreen/loadingscreen.module';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        AppRoutingModule,
        LoadingscreenModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [ApiService] },
        { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
})

export class AppModule { }
