import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { ToastService } from '../../../../core/services/toast.service';
import { LoadingScreenService } from '../../../../shared/components/loadingscreen/loadingscreen.service';

@Component({
    selector: 'app-configs',
    templateUrl: './configs.page.html',
    styleUrls: ['./configs.page.scss'],
})
export class ConfigsPage implements OnInit {

    isLoading: boolean = false

    constructor(private api: ApiService,
        private loadingScreenService: LoadingScreenService,
        private toastService: ToastService) {
    }

    ngOnInit() {

        this.loadingScreenService.componentMethodCalled$.subscribe({
            next: (val: boolean) => {
                this.isLoading = val
            }
        })
    }
    exportRelations() {
        this.api.exportRelations().subscribe({
            next: (data) => {
                const blob = new Blob([data], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const date = new Date();
                a.download = 'Faedis.org - FolderFileRelation ' + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.json';
                //a.download = 'Faedis.org - FolderFileRelation.json';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.toastService.showSuccess('Export Relations', "Export avvenuto con successo", 3000)
            },
            error: (err) => console.log(err),
            complete: () => this.loadingScreenService.setLoading(false)
        })
    }

    folderDump() {
        this.api.folderDump().subscribe({
            next: (res) => { this.toastService.showSuccess('Folder Dump', res.msg, 3000) },
            error: (err) => console.log(err),
            complete: () => this.loadingScreenService.setLoading(false)
        })
    }

    reloadRedis() {
        this.api.reloadRedis().subscribe({
            next: (res) => { this.toastService.showSuccess('Reload Redis', res.msg, 3000) },
            error: (err) => console.log(err),
            complete: () => this.loadingScreenService.setLoading(false)
        })
    }

}
