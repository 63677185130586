import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private alertController: AlertController) { }

    async confirm(message: string, confirmCallback: () => void) {
        const alert = await this.alertController.create({
            header: 'Conferma',
            message: message,
            buttons: [
                {
                    text: 'Annulla',
                    role: 'cancel'
                },
                {
                    text: 'Conferma',
                    handler: confirmCallback
                }
            ]
        });

        await alert.present();
    }
}
