<!--<app-tooltip triggerText="Test" tooltipText="Questo testo di prova!"></app-tooltip>-->

<ion-item *ngIf="displaySearch" lines="full" color="light">
    <ion-icon name="search-outline" slot="start"></ion-icon>
    <ion-input (keyup)="searchNode($event.target.value)" placeholder="Cerca file o cartelle"></ion-input>
</ion-item>

<div id="jstree"></div>
<ion-item *ngIf="folders?.length==0">
    <ion-text>Nessuna cartella o file.</ion-text>
</ion-item>