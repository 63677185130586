import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminPage } from './admin.page';
import { IsUserSuperAdmin } from '../../shared/guards/auth.guard';
import { ConfigsPageModule } from './components/configs/configs.module';
import { GestioneAccessiPageModule } from './components/gestioneAccessi/gestioneAccessi.module';
import { LogsPageModule } from './components/logs/logs.module';

const routes: Routes = [
    {
        path: '',
        component: AdminPage,
        children: [
            {
                path: '',
                redirectTo: 'gestioneAccessi',
                pathMatch: 'full'
            },
            {
                path: 'gestioneAccessi',
                loadChildren: () => GestioneAccessiPageModule
            },
            {
                path: 'logs',
                loadChildren: () => LogsPageModule
            },
            {
                path: 'configs',
                canActivate: [IsUserSuperAdmin],
                loadChildren: () => ConfigsPageModule
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminPageRoutingModule { }
