<div class="background-image"></div>
<div class="blur-overlay"></div> <!-- Overlay con effetto blur -->


<ion-content fullscreen class="ion-padding">
    <ion-grid class="ion-justify-content-center ion-align-items-center" style="height: 100%;">
        <ion-row class="h-100 ion-justify-content-center">
            <ion-col size="12" sizeXl="4" sizeLg="5" size-md="8" class="ion-align-self-center">
                <ion-row class="ion-justify-content-center">
                    <ion-card class="p-5" style="text-align:center">
                        <ion-card-header>
                            <ion-card-title>
                                <ion-img class="w-100 pb-2" src="/assets/images/logo.png"></ion-img>
                            </ion-card-title>

                            <ion-card-subtitle>
                                <ion-label>
                                    <h1>Manutenzione in corso</h1>
                                </ion-label>
                                <ion-icon size="large" name="build-outline"></ion-icon>
                            </ion-card-subtitle>
                        </ion-card-header>

                        <ion-card-content>
                            <ion-text>
                                La Piattaforma Documentale di FFaedi E Co. è attualmente in manutenzione. Stiamo
                                lavorando per
                                migliorare il nostro servizio e saremo di nuovo online al più presto.</ion-text>
                            <ion-text>
                                <br><br>Per aggiornamenti o richieste urgenti contattaci all'indirizzo <a
                                    href="mailto:segreteria@faedis.org">segreteria@faedis.org</a>.
                                <br><br>Grazie per la tua collaborazione.</ion-text>
                        </ion-card-content>

                    </ion-card>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>