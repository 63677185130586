import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FoldersPageRoutingModule } from './folders-routing.module';
import { FoldersPage } from './folders.page';
import { NgxUploaderModule } from '@angular-ex/uploader';
import { FoldertreeModule } from '../../shared/components/foldertree/foldertree.module';


@NgModule({
    imports: [
        NgxUploaderModule,
        CommonModule,
        FormsModule,
        IonicModule,
        FoldersPageRoutingModule,
        FoldertreeModule
    ],
    declarations: [FoldersPage]
})
export class FolderPageModule { }
