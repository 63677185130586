<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar color="light">
        <ion-buttons slot="start">
            <ion-menu-button menuId="sidenav"></ion-menu-button>
        </ion-buttons>
        <ion-title>Configurazioni</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>

        <ion-row>

            <ion-col size-md="4" size-sm="12">
                <ion-list>

                    <ion-item>
                        <ion-label>Esegue il dump delle cartelle dropbox nel database</ion-label>
                        <ion-button slot="end" (click)="folderDump()" [disabled]="isLoading">Folder Dump</ion-button>
                    </ion-item>
                    <ion-item>
                        <ion-label>Esegue il download in formato json delle relazioni cartelle file<>utenti</ion-label>
                        <ion-button slot="end" (click)="exportRelations()" [disabled]="isLoading">Download</ion-button>
                    </ion-item>

                    <ion-item>
                        <ion-label>Ricarica la cache del server Redis</ion-label>
                        <ion-button slot="end" (click)="reloadRedis()" [disabled]="isLoading">Redis Flush</ion-button>
                    </ion-item>
                </ion-list>

            </ion-col>
        </ion-row>

    </ion-grid>
</ion-content>