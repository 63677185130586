import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GestioneAccessiPage } from './gestioneAccessi.page';
import { GestioneAccessiPageRoutingModule } from './gestioneAccessi-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FoldertreeModule } from '../../../../shared/components/foldertree/foldertree.module';

@NgModule({
    imports: [
        NgMultiSelectDropDownModule.forRoot(),
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        FoldertreeModule,
        GestioneAccessiPageRoutingModule
    ],
    declarations: [GestioneAccessiPage]
})
export class GestioneAccessiPageModule { }
